::-webkit-scrollbar {
  height: 14px;
  width: 14px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background: var(--primary-color-opacity);
  background-clip: padding-box;
  cursor: pointer;
  &:hover {
    border-radius: 6px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    background: var(--primary-color-opacity);
    background-clip: padding-box;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}
