@use '@angular/material' as mat;
.custom-dialog-container {
  mat-dialog-container {
    padding: 0;
  }
}

mat-dialog-container {
  .mat-mdc-dialog-surface {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .header {
    padding: 0 24px;
    background-color: mat.get-color-from-palette($primary);
    color: #000000;
    min-height: 48px;
    display: flex;
    align-items: center;
  }
  .container {
    padding: 15px;
    max-height: 65vh;
    overflow-y: auto;
  }
  .footer {
    padding: 12px 24px;
    background: #333333;
  }
}

.dialog-container-p0 mat-dialog-container {
  padding: 0px !important;

  & > * > .header {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.dialog-container-p12 mat-dialog-container {
  padding: 12px !important;

  & > * > .header {
    margin-top: -12px;
    margin-left: -12px;
    margin-right: -12px;
    padding: 0 12px;
  }
}

.dialog-container-p16 mat-dialog-container {
  padding: 16px !important;

  & > * > .header {
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px;
  }
}

.dialog-store-preview mat-dialog-container {
  border-radius: 24px;
  padding: 0px !important;

  & > * > .header {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.profile-bottom-sheet {
  height: 80vh;

  fr-detail {
    width: 100%;
  }
}
