@use '@angular/material' as mat;

@mixin mat-tree-picker-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  mat-tree-picker-node-list {
    .mat-tree-picker-node-list {
      background: mat.get-color-from-palette($background, 'card');

      .mat-menu-content {
        color: mat.get-color-from-palette($foreground, 'text');
      }
    }
  }

  mat-tree-picker-node {
    &.hover:not([disabled]) {
      background: mat.get-color-from-palette($background, 'hover');
    }
  }

  mat-tree-picker-node {
    mat-checkbox {
      label {
        display: inline-block !important;
        width: 100%;
        height: 100%;
      }
    }
  }
}
