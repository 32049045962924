[mat-button].form-field {
  margin-top: 14px;
}

// width
[mat-button].inherit .mdc-button__label {
  width: inherit;
  height: inherit;
  align-items: inherit;
  justify-content: inherit;
  gap: inherit;
  flex-direction: inherit;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: hidden;
  display: inherit;
  position: inherit;
}
