/* material variable */

* {
  --mdc-icon-button-state-layer-size: 36px !important;
  --mat-form-field-container-text-size: 14px !important;
  --mat-select-arrow-transform: 0px;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-option-label-text-size: 14px;
  --mat-optgroup-label-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-select-trigger-text-size: 14px;
  --mdc-dialog-supporting-text-size: 14px;
  --mat-table-row-item-container-height: 40px;
  --mat-table-header-container-height: 45px;
  --mat-paginator-container-size: 45px;
  --mat-dialog-container-max-width: 95vw;
  --mat-table-header-headline-line-height: 0;
}

/* reset tailwind */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
  vertical-align: baseline;
}

img,
video {
  max-width: none;
  height: auto;
}
.error-dialog {
  line-height: 1.5em;
  padding: 24px;
}

/* material style */

.mat-mdc-form-field {
  padding-bottom: 8px;
  .mat-mdc-floating-label,
  .mdc-floating-label {
    max-width: 100%;
  }
}

.mat-mdc-form-field-infix {
  width: 220px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0;
}

.mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

.mdc-form-field > label {
  display: flex;
  align-items: center;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
}

.mat-mdc-icon-button,
.mat-mdc-button-touch-target {
  width: 36px !important;
  height: 36px !important;
}

.mat-mdc-text-field-wrapper,
.mdc-text-field {
  overflow: visible !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: transparent !important;
  border-bottom: dashed 1px rgb(255, 255, 255, 0.38) !important;
}

.mat-dialog-actions .mat-button-base + .mat-button-base,
.mat-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin-left: 8px;
}

.mat-mdc-menu-panel,
.mat-mdc-select-panel {
  max-height: 300px;
  max-width: 600px;
  overflow: auto;
}

.mdc-evolution-chip__text-label {
  user-select: auto !important;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  width: auto !important;
}

mat-card {
  padding: 16px;
}

mat-action-list {
  [mat-list-item] {
    mat-icon {
      vertical-align: top;
    }
  }
}

.mat-expansion-panel-header-title {
  line-height: 1.5;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 22px/32px Noto Sans TC;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 18px/32px Noto Sans TC;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 14px/28px Noto Sans TC;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 14px/24px Noto Sans TC;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 11.62px/20px Noto Sans TC;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 9.38px/20px Noto Sans TC;
}
