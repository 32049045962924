@use '@angular/material' as mat;

$header-height: 60px;
$major-back-color: #312f2d;
$major-font-color: #ffffff;
$back-color: #f4f3ef;
$card-width-em: 21em;
$error-color: #f55564;
$scrollWidth: 10px;
$cardPreviewWidth: 225px;
$cardWidth: 225px;
$cardEditWidth: 300px;
$cardPictureHeight: 200px;
$dialogFooterHeight: 72px;
$dialogMaxHeight: 80vh;
$fontColor: #aaaaaa;
$fontHoverColor: #ffffff;
$backgroundColor: #292929;
$backgroundHoverColor: #323232;
$borderColor: #474747;

$grey-palette: mat.define-palette(mat.$green-palette);

$mat-gosu-theme-background: (
  status-bar: black,
  app-bar: map_get($grey-palette, 900),
  background: #303030,
  hover: rgba(white, 0.04),
  card: map_get($grey-palette, 900),
  dialog: map_get($grey-palette, 900),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get($grey-palette, 900),
  focused-button: rgba(white, 0.12),
  selected-button: map_get($grey-palette, 900),
  selected-disabled-button: map_get($grey-palette, 900),
  disabled-button-toggle: black,
  unselected-chip: map_get($grey-palette, 700),
  disabled-list-option: black,
);

$mat-gosu-theme-foreground: (
  base: white,
  divider: rgba(white, 0.12),
  dividers: rgba(white, 0.12),
  disabled: rgba(white, 0.5),
  disabled-button: rgba(white, 0.3),
  disabled-text: rgba(white, 0.5),
  hint-text: rgba(white, 0.5),
  secondary-text: rgba(white, 0.7),
  icon: white,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);

$gosu-primary: mat.define-palette(mat.$amber-palette, 500, A100, A400);
$gosu-accent: mat.define-palette(mat.$amber-palette, 500, A100, A400);
$gosu-warn: mat.define-palette(mat.$red-palette);
$gosu-theme: mat.define-dark-theme(
  (
    color: (
      primary: $gosu-primary,
      accent: $gosu-accent,
      warn: $gosu-warn,
    ),
  )
);

$primary: map-get($gosu-theme, primary);

$custom-typography: mat.define-typography-config(
  $font-family: 'Noto Sans TC',
);

body {
  --primary-color: #f6c344;
  --primary-color-opacity: #f6c34452;
}
