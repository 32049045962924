@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import './styles/var.scss';
@import './app/material/tree-picker/theme.scss';
@include mat.all-component-themes($gosu-theme);
@include mat.all-component-typographies($custom-typography);
@include mat.core();
@include cdk.overlay();

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

@include mat-tree-picker-theme($gosu-theme);

@import './styles/autofill.scss';
@import './styles/button.scss';
@import './styles/checkbox.scss';
@import './styles/content-menu.scss';
@import './styles/dialog.scss';
@import './styles/icon.scss';
@import './styles/menu.scss';
@import './styles/mixin.scss';
@import './styles/placeholder.scss';
@import './styles/scrollbar.scss';
@import './styles/search.scss';
@import './styles/selection.scss';
@import './styles/sidenav.scss';
@import './styles/tab.scss';
@import './styles/tag.scss';
@import './styles/control-point.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/legacy.scss';
@import './styles/update.scss';

a {
  color: #fff;
  text-decoration: none;
  outline: none;
}
* {
  box-sizing: border-box;
  font-family: 'Noto Sans TC', sans-serif;
}
*[hidden] {
  display: none !important;
}
*[disabled],
.mat-select-disabled {
  cursor: not-allowed;
}
html,
body {
  font-family: 'Noto Sans TC', Arial, sans-serif, Helvetica;
  padding: 0;
  margin: 0;
  height: 100%;
}
kbd {
  font-family: monospace !important;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  line-height: 2em;
  text-shadow: 0 1px 0 #fff;
  display: inline-block;
  white-space: nowrap;
  box-shadow:
    1px 0 1px 0 #eee,
    0 2px 0 2px #ccc,
    0 2px 0 3px #444;
  border-radius: 3px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  margin: 0.25em;
  padding: 0 0.45em;
  min-width: 2em;
  i {
    position: relative;
    top: 0.05em;
    left: 0.05em;
  }
  .mi {
    transform: scale(1.5);
  }
}

.mat-form-field-infix > gosu-color-picker {
  margin-bottom: -0.4375em;
}

.mat-menu-panel {
  min-height: 32px;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url(https://fonts.gstatic.com/s/materialicons/v98/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
      format('woff2'),
    url(/assets/materialicons/v98/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2);
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2)
    format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}

// Google 登入
#g_a11y_announcement {
  display: none;
}
