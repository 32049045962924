.context-menu {
  transform-origin: left top;
}

.tiny-menu {
  button,
  mat-tree-picker-node {
    line-height: 32px;
    font-size: 0.8em;
    height: 32px;
    min-height: 32px;
    vertical-align: top;
  }

  .mat-divider {
    margin: 8px 0;
  }

  mat-icon {
    vertical-align: top !important;
    font-size: 16px;
    line-height: 28px !important;
    margin-right: 4px !important;
  }

  .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
}

.button-menu {
  .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
}
