.mat-form-field-legacy {
  .mdc-text-field {
    padding: 0;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
  .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
}

.mat-form-field-with-error{
  .mat-mdc-form-field-subscript-wrapper {
    height: auto;
  }
}
